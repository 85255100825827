
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getJob, updateJob, getAccountRef, BookingSnap, getBooking } from '@ht-lib/accounts-common'
import { Job, Account, PartialDeep } from '@ht-lib/accounts-models'
import JobTypeContainer from '../components/JobTypeContainer.vue'
import WorksheetComponent from '../components/Worksheet.vue'
import { cloneDeep, merge } from 'lodash'

@Component({
  components: { JobTypeContainer, WorksheetComponent },
  name: 'Worksheet'
})
export default class Worksheet extends Vue {
  @Prop({ type: String, required: true }) jobId!: string
  job: Job | null = null
  bookingSnap!: BookingSnap
  account: Account = null
  doRender = false

  accountSnapshot: () => void
  get booking () {
    return this.bookingSnap.data()
  }

  async created (): Promise<void> {
    console.log('JOBID', this.jobId)
    const jobSnap = await getJob(this.jobId)
    this.job = jobSnap.data()
    this.bookingSnap = await getBooking(this.job.bookingId)
    await this.setupAccount()
    this.doRender = true
  }

  async setupAccount (): Promise<void> {
    return new Promise((resolve) => {
      this.accountSnapshot = getAccountRef(this.job.accountId)
        .onSnapshot((x) => {
          this.account = x.data()
          resolve()
        })
    })
  }

  beforeDestroy () {
    this.accountSnapshot()
  }

  async finished () {
    try {
      await updateJob(this.jobId, this.job)
      await this.$router.push(`/qc/review/${ this.jobId }/grid`)
    } catch (e) {
      console.error(e)
    }
  }

  private updateJob (update: PartialDeep<Job>): void {
    if (this.job) {
      this.job = merge(cloneDeep(this.job), update)
    }
  }
}
