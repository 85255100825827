
import { Component, Prop, Vue } from 'vue-property-decorator'
import { onlineJobTypes } from '@ht-lib/accounts-common'
import { childProtectionImagesQuery, getThumbnailObjectURL } from '@ht-lib/image-util'
import { ImageEntry, Job, AimsCode } from '@ht-lib/accounts-models'
import { getThumbnailBucket } from '@ht-vue/quality-control'

export interface ChildProtectionPreview {
  url: string
  ref: string
}

@Component
export default class extends Vue {
  @Prop({ required: true }) job!: Job
  @Prop({ required: true }) jobId!: string

  onlineJobTypes: AimsCode[] = []
  childProtectionPreviewUrls: ChildProtectionPreview[] = []

  async created (): Promise<void> {
    this.onlineJobTypes = await onlineJobTypes()
    const images = await childProtectionImagesQuery(this.jobId)

    this.childProtectionPreviewUrls = await Promise.all(
      images.map(async doc => this.imageToUrl(doc))
    )
  }

  async imageToUrl (image: ImageEntry): Promise<ChildProtectionPreview> {
    const url = await getThumbnailObjectURL(getThumbnailBucket(), image)
    return { url, ref: image.ref }
  }
}
