
import { Prop, Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'HorizontalStackedText'
})
export default class extends Vue {
  @Prop({ type: String, required: true }) left?: string
  @Prop({ required: true }) right?: string | number
  @Prop({ type: String, required: false }) lClass?: string
  @Prop({ type: String, required: false }) rClass?: string
}
